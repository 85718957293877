import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/tlarson/dev/home/familyhistories/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "histories-of-tolman-family-ancestors"
    }}>{`Histories of Tolman Family Ancestors`}</h3>
    <p>{`Run the `}<a parentName="p" {...{
        "href": "https://github.com/trentlarson/distrinet/#development"
      }}>{`Distrinet`}</a>{` app, use the "Test Settings", and then you'll be able to see histories with semantic tags.`}</p>
    <p>{`The Thomas Tolman Family Organization has histories, and we've started adding semantics there as well. `}<a parentName="p" {...{
        "href": "mailto:trent@trentlarson.com"
      }}>{`Contact me for details.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      